<template>
  <router-link :to="params.link"
               class="card">
    <div class="card__wrapper">
      <Column>
        <div class="card__image-holder">
          <img :src="params.images[0]"
               alt=""
               class="card__image"/>
        </div>
        <div class="card__info-holder">
          <p class="card__info-type">{{params.type.name ? params.type.name : params.type}}</p>
          <div class="card__info-title">{{title}}</div>
          <Row class="card__info-icon-date">
            <Icon class="card__info-icon"
                  viewport="0 0 20 20"
                  xlink="calender"/>
            <span class="card__info-date">
              {{params.started_at ? $moment.unix(params.started_at).format(formatDate) :
              $moment.unix(params.created_at).format(formatDate)}}</span>
          </Row>
        </div>
      </Column>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'ExcursionCard',
  data() {
    return {
      formatDate: 'DD MMMM, HH:mm',
    };
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      const count = this.$viewport.desktop ? 70 : 35;
      let string = this.params.title;
      if (string.length > count) {
        string = string.slice(0, count);
        string = `${string}...`;
      }
      return string;
    },
  },
};
</script>
